export enum MailTemplate {
    FILLABLE_FORM_FILLED_V1 = "FILLABLE_FORM_FILLED_V1",
    FILLABLE_IT2104_REQUEST_V1 = "FILLABLE_IT2104_REQUEST_V1",
    FILLABLE_W4_REQUEST_V1 = "FILLABLE_W4_REQUEST_V1",
    FILLABLE_W8BEN_REQUEST_V1 = "FILLABLE_W8BEN_REQUEST_V1",
    FILLABLE_W9_PERSON_REQUEST_V1 = "FILLABLE_W9_PERSON_REQUEST_V1",
    FILLABLE_W9_COMPANY_REQUEST_V1 = "FILLABLE_W9_COMPANY_REQUEST_V1",
    HOME_PAGE_FEEDBACK_V1 = "HOME_PAGE_FEEDBACK_V1",
    CHAT_UNSEEN_MESSAGE_V1 = "CHAT_UNSEEN_MESSAGE_V1",
    CHAT_REQUESTS_MESSAGE_V1 = "CHAT_REQUESTS_MESSAGE_V1",
    PORTAL_INVITATION_V1 = "PORTAL_INVITATION_V1",
    PASSWORD_RESET_V1 = "PASSWORD_RESET_V1",
    PAYSTUB_V1 = "PAYSTUB_V1",
    FORM_W2_V1 = "FORM_W2_V1",
    FORM_1099_V1 = "FORM_1099_V1",
    FORM_1099_ANONYMOUS_V1 = "FORM_1099_ANONYMOUS_V1",
    CUSTOM_PERSON_FORM_WITHOUT_COMPANY_V1 = "CUSTOM_PERSON_FORM_WITHOUT_COMPANY_V1",
    CUSTOM_COMPANY_FORM_V1 = "CUSTOM_COMPANY_FORM_V1",
    PAYROLL_REPORT_V1 = "PAYROLL_REPORT_V1",
    DOCUMENT_SIGNED = "DOCUMENT_SIGNED",
    BUSINESS_TAX_RETURN_V1 = "BUSINESS_TAX_RETURN_V1",
    BANK_ACCOUNT_NEEDS_VERIFICATION_V1 = "BANK_ACCOUNT_NEEDS_VERIFICATION_V1",
    CBSR_NEW_V1 = "CBSR_NEW_V1",
    CBSR_NEW_DOC_UPLOADED_V1 = "CBSR_NEW_DOC_UPLOADED_V1",
    W9CR_NEW_V1 = "W9CR_NEW_V1",
    W9CR_PERSON_FORM_V1 = "W9CR_PERSON_FORM_V1",
    W9CR_COMPANY_FORM_V1 = "W9CR_COMPANY_FORM_V1",
    W9CR_UPDATED_V1 = "W9CR_UPDATED_V1"
}