import axios from "axios";
import MailRegistryRecordDTO from "@/dto/mail/MailRegistryRecordDTO";
import {MAIL_REGISTRY_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import Page from "@/dto/taxation/Page";
import {MailTemplate} from "@/constants/MailConstants";
import QueryUtils from "@/utils/QueryUtils";

class MailService {

    getRegistry(template: MailTemplate | null, recipient: string | null, messageId: string | null, page: number, pageSize: number){
        const queryString = QueryUtils.buildQueryString(
            ["template", template],
            ["recipient", recipient],
            ["messageId", messageId],
            ["page", page],
            ["pageSize", pageSize]
        )
        return axios.get<Page<MailRegistryRecordDTO>>(MAIL_REGISTRY_ENDPOINT + queryString, {headers: authHeader()});
    }

    resendMessage(messageId: string){
        return axios.put(`${MAIL_REGISTRY_ENDPOINT}/${messageId}`, null, {headers: authHeader()});
    }

}

export default new MailService();